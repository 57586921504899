export const colors = {
  lightGreen: '#11BA5F',
  lightGrey: '#FBFBFB',
  mediumGrey: '#BDBDBD',
  darkBlue: '#081427',
  darkGreen: '#0E8F4F',
  lightBlue: '#D5DDF9',
  white: '#FFFFFF',
  grey: '#7B7B7B',
};
