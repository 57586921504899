import { Box } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';

const CenterTableItems = ({ children }: any) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    {children}
  </Box>
);

export const columns: ColumnsType[] = [
  {
    id: 'subAccountUserId',
    label: 'Sub-account',
    minWidth: 100,
    align: 'center',
    render: ({ subAccountUserId }: any) => (
      <CenterTableItems>{subAccountUserId}</CenterTableItems>
    ),
  },
  {
    id: 'machineModel',
    label: 'Model',
    minWidth: 100,
    align: 'center',
    render: ({ machineModel }: any) => (
      <CenterTableItems>{machineModel}</CenterTableItems>
    ),
  },
  {
    id: 'numberOfMachines',
    label: 'Total miners',
    minWidth: 100,
    align: 'center',
    render: ({ numberOfMachines }: any) => (
      <CenterTableItems>{numberOfMachines}</CenterTableItems>
    ),
  },
  {
    id: 'machineWatt',
    label: 'Power, kW',
    minWidth: 100,
    align: 'center',
    render: ({ machineWatt }: any) => (
      <CenterTableItems>{machineWatt.toLocaleString('en-EN')}</CenterTableItems>
    ),
  },
  {
    id: 'machineTH',
    label: 'Total hashrate, TH/s',
    minWidth: 100,
    align: 'center',
    render: ({ machineTH }: any) => (
      <CenterTableItems>{machineTH.toLocaleString('en-EN')}</CenterTableItems>
    ),
  },
  {
    id: 'electricityCost',
    label: 'Electricity cost',
    minWidth: 100,
    align: 'center',
    render: ({ electricityCost }: any) => (
      <CenterTableItems>
        $
        {Number(electricityCost).toLocaleString('en-EN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </CenterTableItems>
    ),
  },
];
