import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import { UserRole } from 'shared/utils/enum/UserEnum';
import * as yup from 'yup';
import { IFormInput, IProps } from '../types';
import {
  buttons,
  container,
  content,
  formTitle,
  submitButton,
} from 'components/Forms/styles';
import { ControlledSelect } from 'components/ControlledInputs/Select';

export const CustomerForm: FC<IProps> = ({
  setShowModal,
  editData,
  onFinish,
}): JSX.Element => {
  const { showSnackbar } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [affiliateAccounts, setAffiliateAccounts] = useState<any[]>([]);

  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').email().trim(),
    username: yup.string().required('Username is a required field'),
    password: editData?.id
      ? yup.string()
      : yup
          .string()
          .required('Password is a required field')
          .min(8, 'Password must be at least 8 characters'),
    companyName: yup.string().required('Company name is a required field'),
    walletAddress: yup.string().notRequired(),
    ethWalletAddress: yup.string().notRequired(),
  });

  const fetchAffiliates = async () => {
    setAffiliateAccounts([]);
    try {
      const { users } = await UsersService.getAllAffiliates();

      setAffiliateAccounts(users);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const affiliateAccountsItems = useMemo(() => {
    return affiliateAccounts.map((item) => ({
      value: item.id,
      name: item.username,
    }));
  }, [affiliateAccounts]);

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formValues = useMemo(
    () =>
      editData
        ? {
            companyName: editData.companyName,
            email: editData.email,
            password: editData.password,
            username: editData.username,
            walletAddress: editData.walletAddress,
            ethWalletAddress: editData.ethWalletAddress,
          }
        : {
            companyName: '',
            email: '',
            password: '',
            username: '',
            walletAddress: '',
            ethWalletAddress: '',
          },
    [editData],
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (
    userData,
  ): Promise<void> => {
    const data = {
      ...userData,
      role: UserRole.CUSTOMER,
    };

    try {
      if (editData) {
        await UsersService.updateUser(editData?.id, data);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await UsersService.createUser(data);
        showSnackbar('Successfully Created', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      onFinish();
      setShowModal(false);
    }
  };

  return (
    <>
      <Box sx={container}>
        <form style={content} onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={formTitle}>
            {editData ? 'Edit' : 'Add'} Customer
          </Typography>
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.username ?? ''}
            name="username"
            label="Username"
            error={errors}
            placeholder="Username"
            disableAutocomplete
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.email ?? ''}
            name="email"
            label="Email"
            error={errors}
            placeholder="name.example@gmail.com"
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.companyName ?? ''}
            name="companyName"
            label="Company name"
            error={errors}
            placeholder="Company name"
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.walletAddress ?? ''}
            name="walletAddress"
            label="Bitcoin (BTC) wallet address"
            error={errors}
            placeholder="Bitcoin (BTC) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.kaspaWalletAddress ?? ''}
            name="kaspaWalletAddress"
            label="Kaspa (KAS) wallet address"
            error={errors}
            placeholder="Kaspa (KAS) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.litecoinWalletAddress ?? ''}
            name="litecoinWalletAddress"
            label="Litecoin (LTC) / Dogecoin (DOGE) wallet address"
            error={errors}
            placeholder="Litecoin (LTC) / Dogecoin (DOGE) wallet address"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.ethWalletAddress ?? ''}
            name="ethWalletAddress"
            label="Ethereum wallet address"
            error={errors}
            placeholder="Ethereum wallet address"
          />
          <ControlledSelect
            control={control}
            name="affiliate"
            defaultValue={editData ? editData.affiliate?.id : null}
            label="Related Affiliate"
            error={errors}
            options={affiliateAccountsItems}
          />
          {!editData?.id && (
            <ControlledTextField
              control={control}
              defaultValue=""
              name="password"
              label="Password"
              placeholder="Password"
              error={errors}
              required
              disableAutocomplete
              type={showPassword ? 'text' : 'password'}
              textFieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <SvgComponentSee />
                        ) : (
                          <SvgComponentHide />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          <Box sx={buttons}>
            <Button sx={submitButton} type="submit">
              {editData ? 'Save changes' : 'Add user'}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
