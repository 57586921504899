import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledAutocomplete } from 'components/ControlledInputs/Autocomplete';
import SimulationService from 'shared/services/simulation.service';
import countryCodes from 'shared/constants/country-codes.json';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { container, content, formTitle } from '../styles';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { Currency } from 'shared/utils/enum/coinEnums';
import { useAuth } from 'shared/hooks/useAuth';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { handleCurrencyName } from 'shared/utils/currency';
import { colors } from 'shared/constants/theme';

const availability = [
  { label: '1 Month', value: 1 },
  { label: '2 Month', value: 2 },
  { label: '3 Month', value: 3 },
  { label: 'More than 3 months', value: 4 },
];

const radioButtonOptions = [
  { label: 'Automatically', value: 'Automatically' },
  { label: 'Manually', value: 'Manually' },
];

const currencies = Object.values(Currency).map((value) => ({
  value,
  name: handleCurrencyName(value),
}));

const upfrontHostingOptions = Array.from({ length: 13 }, (_, index) => ({
  name: index,
  value: index,
}));

const handleInitialLocations = (locations: any) => {
  if (locations && locations.length) {
    const initialLocations: any = [];
    locations.forEach((location: any) => {
      const country = countryCodes.countries.find(
        (item: any) => item.alpha2 === location,
      );
      initialLocations.push(country);
    });

    return initialLocations;
  }

  return [];
};

export const AdminSimulationForm = ({
  setIsLoading,
  setSimulationState,
  setSimulationData,
  setClientName,
  clientName,
  generateInitialValues,
  setGenerateInitialValues,
  handleClose,
}: any): JSX.Element => {
  const [selectedLocations, setSelectedLocations] = useState<any[]>(
    handleInitialLocations(generateInitialValues?.locations),
  );
  const [locationText, setLocationText] = useState<string>('');
  const [deliveryTime, setDeliveryTime] = useState<any>(
    generateInitialValues?.time ?? 1,
  );
  const [revenuePerHashrateCalculation, setRevenuePerHashrateCalculation] =
    useState<any>(
      generateInitialValues?.revenuePerHashrateCalculation ?? 'Automatically',
    );

  const [managementFeeOption, setManagementFeeOption] = useState<any>(
    generateInitialValues?.managementFeeOption ?? 'Automatically',
  );

  const { showSnackbar } = useNotification();

  const { user } = useAuth();

  const schema = yup.object().shape({
    totalAssets: yup
      .number()
      .typeError('Please, specify total assets')
      .required('Total assets is a required field'),
    hearstUpfront: yup
      .number()
      .typeError('Please, specify Hearst upfront %')
      .required('Hearst upfront is a required field')
      .max(100, 'Hearst upfront cannot be more than 100'),
    upfrontHosting: yup
      .number()
      .typeError('Please, specify upfront hosting (in months)')
      .oneOf(
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        'Upfront hosting must be between 0 and 12',
      )
      .required('Upfront hosting is a required field'),
    revenue: yup.number().when([], {
      is: () => revenuePerHashrateCalculation === 'Manually',
      then: (schema) => schema.required('Revenue is required'),
      otherwise: (schema) => schema,
    }),
    min: yup.number().when([], {
      is: () => revenuePerHashrateCalculation === 'Manually',
      then: (schema) => schema.required('Min is required'),
      otherwise: (schema) => schema,
    }),
    average: yup.number().when([], {
      is: () => revenuePerHashrateCalculation === 'Manually',
      then: (schema) => schema.required('Avarage is required'),
      otherwise: (schema) => schema,
    }),
    max: yup.number().when([], {
      is: () => revenuePerHashrateCalculation === 'Manually',
      then: (schema) => schema.required('Max is required'),
      otherwise: (schema) => schema,
    }),
    fee: yup.number().when([], {
      is: () => managementFeeOption === 'Manually',
      then: (schema) => schema.required('Management Fee is required'),
      otherwise: (schema) => schema,
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm({ resolver: yupResolver(schema) });

  const currencyChange = useWatch({ control, name: 'currency' });

  const rateCountValue = useMemo(
    () => (currencyChange === Currency.Litecoin ? 'MH/s' : 'TH/s'),
    [currencyChange],
  );

  const handleUpfrontDefaultValue = () => {
    if (
      user.role === UserRole.AFFILIATE ||
      user.role === UserRole.AFFILIATE_SUBACCOUNT
    )
      return '3';
    if (generateInitialValues?.hearstUpfront)
      return generateInitialValues?.hearstUpfront;

    return '';
  };

  const handleUpfrontHostingDefaultValue = () => {
    if (
      user.role === UserRole.AFFILIATE ||
      user.role === UserRole.AFFILIATE_SUBACCOUNT
    )
      return '2';
    if (generateInitialValues?.upfrontHosting)
      return generateInitialValues?.upfrontHosting;

    return '';
  };

  const onSubmit: any = async (payload: any): Promise<void> => {
    setIsLoading(true);
    try {
      const locations = selectedLocations.map((item) => item.alpha2);
      let manualValues: any = {};

      if (revenuePerHashrateCalculation === 'Manually') {
        manualValues.revenue = Number(payload.revenue);
        manualValues.min = Number(payload.min);
        manualValues.average = Number(payload.average);
        manualValues.max = Number(payload.max);
      }

      if (managementFeeOption === 'Manually')
        manualValues.fee = Number(payload.fee);

      const response = await SimulationService.generateSimulation({
        locations: locations.join(','),
        time: deliveryTime,
        investment: payload.totalAssets,
        hearstUpfront: payload.hearstUpfront,
        upfrontHosting: payload.upfrontHosting,
        currency: payload.currency,
        ...manualValues,
      });

      setClientName(payload.clientName);

      setSimulationData(response);
      setSimulationState('generated');

      setGenerateInitialValues({
        locations: locations,
        time: deliveryTime,
        investment: payload.totalAssets,
        hearstUpfront: payload.hearstUpfront,
        upfrontHosting: payload.upfrontHosting,
        currency: payload.currency,
        revenuePerHashrateCalculation: revenuePerHashrateCalculation,
        managementFeeOption: managementFeeOption,
        ...manualValues,
      });
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutomaticallySelect = () => {
    resetField('revenue');
    resetField('min');
    resetField('average');
    resetField('max');
  };

  useEffect(() => {
    if (revenuePerHashrateCalculation === 'Automatically')
      handleAutomaticallySelect();

    if (managementFeeOption === 'Automatically') resetField('fee');
  }, [revenuePerHashrateCalculation, managementFeeOption]);

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>Create proposal simulation</Typography>
        <Box sx={{ marginBottom: '20px', width: '100%' }}>
          <ControlledTextField
            control={control}
            defaultValue={clientName}
            name="clientName"
            label="Prospect Name"
            error={errors}
            maxLength={256}
            placeholder="Prospect Name"
          />
        </Box>
        <Box sx={{ marginBottom: '20px', width: '100%' }}>
          <ControlledSelect
            control={control}
            name="currency"
            defaultValue={generateInitialValues?.currency ?? 'Bitcoin'}
            label="Cryptocurrency"
            error={errors}
            options={currencies}
          />
        </Box>
        <Box
          sx={{
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            columnGap: '40px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <ControlledTextField
              control={control}
              name="hearstUpfront"
              label="Hearst upfront % (one time)"
              error={errors}
              type="number"
              placeholder="Hearst upfront"
              disabled={
                user.role === UserRole.AFFILIATE ||
                user.role === UserRole.AFFILIATE_SUBACCOUNT
              }
              defaultValue={handleUpfrontDefaultValue()}
            />
          </Box>
          <ControlledSelect
            control={control}
            name="upfrontHosting"
            defaultValue={handleUpfrontHostingDefaultValue()}
            label="Upfront hosting, month"
            placeholder="Upfront hosting"
            disabled={
              user.role === UserRole.AFFILIATE ||
              user.role === UserRole.AFFILIATE_SUBACCOUNT
            }
            error={errors}
            options={upfrontHostingOptions}
          />
        </Box>
        <Box sx={{ marginBottom: '20px', width: '100%' }}>
          <ControlledTextField
            control={control}
            name="totalAssets"
            label="Total assets, $"
            error={errors}
            type="number"
            placeholder="Total assets, $"
            defaultValue={generateInitialValues?.investment ?? ''}
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: '500',
            fontSize: '16px',
          }}
        >
          Choose locations to exclude from the hosting opportunities
        </Typography>
        <ControlledAutocomplete
          initialLabel=""
          optionLabel="country"
          name="location"
          options={countryCodes.countries}
          value={selectedLocations}
          setValue={setSelectedLocations}
          valueText={locationText}
          setValueText={setLocationText}
          control={control}
          width="100%"
          errorText="Location is a required field"
          multiple={true}
        />
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: '500',
            fontSize: '16px',
            marginTop: '40px',
            marginBottom: '5px',
          }}
        >
          Choose max delivery time
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid #EAEAEA',
            width: '100%',
            padding: '20px',
          }}
        >
          <RadioGroup
            value={deliveryTime}
            onChange={(e) => {
              setDeliveryTime(parseInt(e.target.value));
            }}
            sx={{ display: 'flex', rowGap: '10px' }}
          >
            {availability.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  labelPlacement="start"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      width: '100%',
                      marginLeft: '10px',
                    },
                    padding: '5px',
                    margin: 0,
                    borderRadius: '8px',
                    background:
                      item.value == deliveryTime
                        ? 'rgba(17, 186, 95, 0.3)'
                        : '',
                  }}
                />
              );
            })}
          </RadioGroup>
        </Box>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: '500',
            fontSize: '16px',
            marginTop: '40px',
            marginBottom: '5px',
          }}
        >
          Define revenue per hashrate
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid #EAEAEA',
            width: '100%',
            padding: '20px',
          }}
        >
          <RadioGroup
            value={revenuePerHashrateCalculation}
            onChange={(e) => {
              setRevenuePerHashrateCalculation(e.target.value);
            }}
            sx={{ display: 'flex', rowGap: '10px' }}
          >
            {radioButtonOptions.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  labelPlacement="end"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      width: '100%',
                      marginLeft: '10px',
                    },
                    padding: '5px',
                    margin: 0,
                    borderRadius: '8px',
                  }}
                />
              );
            })}
          </RadioGroup>
          {revenuePerHashrateCalculation === 'Manually' ? (
            <Box>
              <Box sx={{ marginBottom: '20px', width: '100%' }}>
                <ControlledTextField
                  control={control}
                  name="revenue"
                  label={`Revenue, $/${rateCountValue}`}
                  error={errors}
                  placeholder={`Revenue, $/${rateCountValue}`}
                  defaultValue={generateInitialValues?.revenue}
                  type="number"
                />
              </Box>
              <Box
                sx={{
                  marginBottom: '20px',
                  width: '100%',
                  display: 'flex',
                  columnGap: '40px',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  },
                }}
              >
                <ControlledTextField
                  control={control}
                  name="min"
                  label={`Min, $/${rateCountValue}`}
                  error={errors}
                  placeholder={`Min, $/${rateCountValue}`}
                  defaultValue={generateInitialValues?.min}
                  type="number"
                />
                <ControlledTextField
                  control={control}
                  name="average"
                  label={`Average, $/${rateCountValue}`}
                  error={errors}
                  placeholder={`Average, $/${rateCountValue}`}
                  defaultValue={generateInitialValues?.average}
                  type="number"
                />
                <ControlledTextField
                  control={control}
                  name="max"
                  label={`Max, $/${rateCountValue}`}
                  error={errors}
                  placeholder={`Max, $/${rateCountValue}`}
                  defaultValue={generateInitialValues?.max}
                  type="number"
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: '500',
            fontSize: '16px',
            marginTop: '40px',
            marginBottom: '5px',
          }}
        >
          Define Hearst management fee
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid #EAEAEA',
            width: '100%',
            padding: '20px',
          }}
        >
          <RadioGroup
            value={managementFeeOption}
            onChange={(e) => {
              setManagementFeeOption(e.target.value);
            }}
            sx={{ display: 'flex', rowGap: '10px' }}
          >
            {radioButtonOptions.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  labelPlacement="end"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      width: '100%',
                      marginLeft: '10px',
                    },
                    padding: '5px',
                    margin: 0,
                    borderRadius: '8px',
                  }}
                />
              );
            })}
          </RadioGroup>
          {managementFeeOption === 'Manually' ? (
            <Box>
              <ControlledTextField
                control={control}
                name="fee"
                label="Hearst management fee, %"
                error={errors}
                placeholder="Hearst management fee, %"
                defaultValue={generateInitialValues?.fee}
                type="number"
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            sx={{
              marginRight: '10px',
              background: '#eeeeee',
              color: 'black',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#eeeeee',
              },
            }}
            onClick={() => handleClose()}
            type="button"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              borderRadius: '10px',
              padding: '0px 20px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            type="submit"
          >
            Proceed to simulation preview
          </Button>
        </Box>
      </form>
    </Box>
  );
};
