import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { Statistics } from 'components/Statistics';
import { colors } from 'shared/constants/theme';
import { utcDateTimeNow } from 'shared/constants/date-time';
import { DashboardStatistics } from 'shared/types/dashboard';

type Props = {
  statistics?: DashboardStatistics;
};
export const Operations = ({ statistics }: Props) => {
  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Investments',
        value: statistics
          ? `$${statistics.totalInvestment.toLocaleString('en-EN', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : null,
        currency: 'USD',
      },
      {
        title: 'Total Bitcoin hashrate',
        value: statistics ? statistics.bitcoinHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
      {
        title: 'Total Kaspa hashrate',
        value: statistics ? statistics.kaspaHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
    ],
    [statistics],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name="Your operations"
        />
        <Typography color={colors.mediumGrey}>{utcDateTimeNow}</Typography>
      </Box>
      <Statistics statistics={statisticsData} />
    </>
  );
};
