import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { linePlugin } from 'components/Charts/plugins';
import {
  defaultLineChartStyles,
  setLinearGradientColor,
} from 'components/Charts/styles';
import { Filter } from 'components/Filter';
import { Layout } from 'components/Layout';
import { StatisticsCard } from 'components/Cards/StatisticsCard';
import moment from 'moment';
import { threeRowGridItem } from 'pages/Customer/Operation/styles';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import DashboardService from 'shared/services/dashboard.service';
import HashrateService from 'shared/services/hashrate.service';
import { getSixPreviousMonths } from 'shared/utils/enum/months';
import { formatNumberToTerra } from 'shared/utils/formatNumberToTerra';
import { colors } from 'shared/constants/theme';
import { SectionHeading } from 'components/SectionHeading';
import { useAuth } from 'shared/hooks/useAuth';
import { InvoiceEstimatesTable } from 'components/Tables/InvoiceEstimatesTable';
import { HosterContractsTable } from 'components/Tables/HosterContractsTable';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useNotification } from 'shared/hooks/useNotification';
import { utcDateTimeNow } from 'shared/constants/date-time';

export const Supplier = () => {
  const [uptime, setUptime] = useState<any>(null);
  const [statistics, setStatistics] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>({
    contracts: [],
  });
  const [type, setType] = useState('hashrate');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalMiners, setTotalMiners] = useState(0);
  const { user } = useAuth();
  const { showSnackbar } = useNotification();

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  const sixPreviousMonths = getSixPreviousMonths();

  const getSupplierUptime = async () => {
    const { totalPages, ...uptime } =
      await HashrateService.getSupplierContractsAverageUptime({
        pageNumber: currentPage,
        limit: rowsPerPage,
      });
    setUptime(uptime);
    setTotalPages(totalPages);
  };

  const getSupplierContractsStatistics = async (contractIds?: string[]) => {
    const { chart, ...statistics } =
      await HashrateService.getSupplierContractsStatistics({ contractIds });
    setStatistics(statistics);
  };

  useEffect(() => {
    getSupplierUptime();
  }, [currentPage, rowsPerPage]);

  const getSupplierDashboardFilterData = async () => {
    try {
      const data = await DashboardService.getSupplierDashboardFilterData();
      return setFilterData(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getHosterTotalMiners = async () => {
    try {
      const data = await DashboardService.getHosterTotalMiners();

      return setTotalMiners(data.totalMiners);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const subAccountUserIds = useMemo(
    () => filterData.contracts.map((contract: any) => contract.name).sort(),
    [filterData.contracts],
  );

  const getFilteredData: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    setStatistics(null);
    await getSupplierContractsStatistics(contractIds);
  };

  const data = useMemo(() => {
    const hashrate = statistics ? statistics[type].chart : [];
    return {
      labels: hashrate.map((elem: any) => elem?.date),
      datasets: [
        {
          data: hashrate.map((elem: any) => elem?.speed),
          borderColor: colors.lightGreen,
          fill: true,
          pointBackgroundColor: colors.lightGreen,
          pointRadius: 0,
          borderWidth: 1.5,
          backgroundColor: (context: any) => setLinearGradientColor(context),
        },
      ],
    };
  }, [statistics, type]);

  useEffect(() => {
    getSupplierUptime();
    getSupplierDashboardFilterData();
    getSupplierContractsStatistics();
    getHosterTotalMiners();
  }, []);

  const dateColumns = useMemo(() => {
    return sixPreviousMonths.map((month) => ({
      id: month.toLowerCase(),
      label: `${month.split('-')[0]} ${month.split('-')[1]}`,
      align: 'left',
      render: (data: any) => {
        const value = data[month.toLowerCase()];
        if (value === 'Not Plugged') {
          return value;
        }
        return type === 'hashrate' ? `${value} PH/s` : `${value}%`;
      },
    }));
  }, [type]);

  const columns: any = [
    {
      id: 'contract',
      label: 'Contract',
      align: 'left',
    },
    ...dateColumns,
  ];

  const statisticsData = useMemo(
    () => [
      {
        title: `${type === 'percentage' ? 'Uptime' : 'Hashrate'} this month`,
        value: statistics ? statistics[type].startOfMonthUptime : null,
        currency: type === 'percentage' ? '%' : 'PH/S',
        date: moment().format('MMMM YYYY'),
      },
      {
        title: `${type === 'percentage' ? 'Uptime' : 'Hashrate'} past month`,
        value: statistics ? statistics[type].lastMonthUptime : null,
        currency: type === 'percentage' ? '%' : 'PH/S',
        date: moment().subtract(1, 'months').format('MMMM YYYY'),
      },
      {
        title: `${type === 'percentage' ? 'Uptime' : 'Hashrate'} since beginning`,
        value: statistics ? statistics[type].beginningUptime : null,
        currency: type === 'percentage' ? '%' : 'PH/S',
        date: statistics
          ? `since ${moment(statistics.hashrate.beginningDate).format('MMMM YYYY')}`
          : null,
      },
    ],
    [statistics, type],
  );
  const max = Math.max(...data.datasets[0].data);

  const calculatedUptime = useMemo(() => {
    return uptime ? uptime[type] : null;
  }, [uptime, type]);

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  return (
    <Layout backgroundHeight="440px">
      <SectionHeading
        name={`Welcome back, ${user?.username}`}
        sx={{
          fontSize: {
            xs: 32,
            sm: 40,
            md: 48,
            lg: 52,
          },
          color: '#fff',
          marginBottom: '30px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SectionHeading
          sx={{
            color: '#fff',
            marginBottom: '20px',
          }}
          name="Your operations"
        />
        <Typography color={colors.mediumGrey}>{utcDateTimeNow}</Typography>
      </Box>
      <Box sx={{ marginBottom: '40px' }}>
        <StatisticsCard
          colored
          title="Total miners"
          value={totalMiners ? totalMiners : null}
          currency=""
          small
        />
      </Box>
      <InvoiceEstimatesTable />
      <HosterContractsTable />
      <SectionHeading
        sx={{
          color: '#000',
          marginBottom: '20px',
          marginTop: '40px',
        }}
        name="Statistics"
      />
      <Box sx={{ borderRadius: '20px' }}>
        <Paper
          sx={{
            backgroundColor: '#fff',
            color: '#131613',
            borderRadius: '20px',
            boxShadow: '',
            padding: '20px',
          }}
        >
          <FormControl>
            <FormLabel>Type</FormLabel>
            <RadioGroup row value={type} onChange={handleTypeChange}>
              <FormControlLabel
                value="hashrate"
                control={<Radio />}
                label="Hashrate"
              />
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="Percentage"
              />
            </RadioGroup>
          </FormControl>
          <RedesignedTable
            items={calculatedUptime}
            columns={columns}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={handleCountItems}
            rowsPerPage={rowsPerPage}
            wrapped={false}
          />
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SectionHeading
          sx={{
            color: '#000',
            marginTop: '40px',
          }}
          name="Average Hashrate and Uptime"
        />
        <Filter contracts={subAccountUserIds} onFilter={getFilteredData} />
      </Box>
      <Grid marginTop={0.5} container rowSpacing={2} columnSpacing={2}>
        {statisticsData.map((statistic: any) => (
          <Grid {...threeRowGridItem}>
            <StatisticsCard {...statistic} small />
          </Grid>
        ))}
      </Grid>

      <Grid marginTop={2}>
        <InfoCard
          info="Your Hashrate"
          styles={{
            height: '690px',
          }}
          subtitle="Hashrate"
          fullHeight
          content={
            statistics ? (
              <Line
                options={{
                  elements: defaultLineChartStyles.elements,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      ticks: {
                        minRotation: 0,
                        maxRotation: 0,
                        maxTicksLimit: 7,
                        callback(tickValue: any) {
                          return moment(data.labels[tickValue]).format(
                            'MMM YYYY',
                          );
                        },
                      },
                    },
                    y: {
                      min: 0,
                      max:
                        type === 'percentage' ? undefined : Math.round(max) * 2,
                      ticks: {
                        maxTicksLimit: 8,
                        callback: (value: any) => {
                          return `${formatNumberToTerra(value)}${
                            type === 'percentage' ? '%' : ' PH/s'
                          }`;
                        },
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      ...defaultLineChartStyles.plugins.tooltip,
                      callbacks: {
                        title: (item: any) => {
                          const label = item[0].label;
                          return moment(label).format('MMM YYYY');
                        },
                        label: (item: any) =>
                          `Hashrate ${item.formattedValue}${
                            type === 'percentage' ? '%' : ' PH/s'
                          }`,
                      },
                    },
                  },
                  interaction: {
                    mode: 'index',
                    intersect: false,
                  },
                }}
                data={data}
                plugins={[linePlugin]}
              />
            ) : (
              <Skeleton variant="rectangular" height="100%" />
            )
          }
        />
      </Grid>
    </Layout>
  );
};
