import { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete, FileUploadOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import hostingService from 'shared/services/hosting.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import countryCodes from 'shared/constants/country-codes.json';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import { ControlledAutocomplete } from 'components/ControlledInputs/Autocomplete';
import FilesService from 'shared/services/files.service';
import { container, content, formTitle } from '../styles';
import { colors } from 'shared/constants/theme';

export const AdminHostingForm = ({
  setShowModal,
  editData,
  onFinish,
  suppliers,
  setIsLoading,
}: any): JSX.Element => {
  const [selectedLocation, setSelectedLocation] = useState<any>(
    editData
      ? countryCodes.countries.find(
          (country: any) => country.alpha2 === editData.location,
        )
      : null,
  );
  const [locationText, setLocationText] = useState<any>('');
  const [selectedSupplier, setSelectedSupplier] = useState<any>(
    editData && suppliers?.length > 0
      ? suppliers?.find((supplier: any) => supplier.id === editData.supplier.id)
      : null,
  );
  const [supplierText, setSupplierText] = useState<any>('');
  const [file, setFile] = useState<any>();
  const [editDataFile, setEditDataFile] = useState<any>(
    editData?.image ? editData?.image : null,
  );
  const [isLocationError, setIsLocationError] = useState(false);
  const [isSupplierError, setIsSupplierError] = useState(false);
  const [isPriority, setIsPriority] = useState(
    editData ? editData.priority : false,
  );
  const { showSnackbar } = useNotification();

  useEffect(() => {
    if (selectedLocation?.alpha2) {
      setIsLocationError(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedSupplier?.id) {
      setIsSupplierError(false);
    }
  }, [selectedSupplier]);

  const schema = yup.object().shape({
    name: yup.string().required('Opportunity name is a required field'),
    installationCost: yup
      .number()
      .required('Installation cost is a required field'),
    electricityCost: yup
      .number()
      .required('Electricity cost is a required field'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleClick = (event: any) => {
    if (!selectedLocation?.alpha2 || !selectedSupplier?.id) {
      event.preventDefault();
      if (!selectedLocation?.alpha2) {
        setIsLocationError(true);
      }
      if (!selectedSupplier?.id) {
        setIsSupplierError(true);
      }
      return;
    }
  };

  const onSubmit: any = async (hostingData: any): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = {
        ...hostingData,
        location: selectedLocation.alpha2,
        supplier: selectedSupplier.id,
        priority: isPriority,
      };

      if (file) {
        const fileFormData = new FormData();
        fileFormData.append('file', file);
        const { fileName } = await FilesService.uploadFile(fileFormData);

        payload.image = fileName;
      }

      if (editData) {
        await hostingService.updateHosting(editData.id, {
          ...hostingData,
          location: selectedLocation.alpha2,
          supplier: selectedSupplier.id,
          image: payload.image ? payload.image : editData.image,
          priority: isPriority,
        });
        showSnackbar('Successfully Edited', 'success');
      } else {
        await hostingService.createHosting(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Create'} hosting opportunity
        </Typography>
        <Box width="100%" display="flex" flexDirection="column" rowGap="20px">
          <ControlledAutocomplete
            label="Supplier name"
            initialLabel="Choose supplier"
            optionLabel="username"
            name="supplier"
            options={suppliers}
            value={selectedSupplier}
            setValue={setSelectedSupplier}
            valueText={supplierText}
            setValueText={setSupplierText}
            control={control}
            width="100%"
            error={isSupplierError}
            errorText="Supplier is a required field"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.name ? editData?.name : ''}
            name="name"
            label="Hosting opportunity name"
            error={errors}
            placeholder="Opportunity name"
            width="100%"
          />
          <Box width="100%" display="flex" alignItems="center" columnGap="30px">
            <ControlledAutocomplete
              label="Site location"
              initialLabel="Choose location"
              optionLabel="country"
              name="location"
              options={countryCodes.countries}
              value={selectedLocation}
              setValue={setSelectedLocation}
              valueText={locationText}
              setValueText={setLocationText}
              control={control}
              width="100%"
              error={isLocationError}
              errorText="Location is a required field"
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isPriority}
              onChange={() => setIsPriority(!isPriority)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              fontWeight="500"
              fontSize="16px"
              lineHeight="15px"
              color="#666666"
            >
              Priority
            </Typography>
          </Box>
          <Box
            sx={{ width: '100%' }}
            display="flex"
            flexDirection="column"
            columnGap={0.5}
            rowGap={0.5}
            marginY={0.5}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              columnGap={0.5}
              rowGap={0.5}
              marginY={0.5}
              width="100%"
            >
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Location image
              </Typography>
            </Box>
            <Box
              sx={{
                border: '1px dashed #d9d9d9',
                borderRadius: '8px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  bgcolor: 'transparent',
                  color: colors.lightGreen,
                  border: '1px solid #d9d9d9',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="contained"
                startIcon={
                  <FileUploadOutlined sx={{ color: colors.lightGreen }} />
                }
              >
                Upload
                <VisuallyHiddenInput
                  onChange={(e) => setFile(e.target.files![0])}
                  accept=".jpg, .jpeg, .png"
                  type="file"
                />
              </Button>
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Upload location image
              </Typography>
              {file && (
                <Box display="flex" gap={1} alignItems="center" mt={1}>
                  {file.name.length > 18
                    ? `${file.name.substring(0, 18)}...`
                    : file.name}
                  <IconButton
                    onClick={() => setFile(null)}
                    aria-label="delete"
                    sx={{
                      '&:hover': {
                        background: 'white !important',
                      },
                    }}
                  >
                    <Delete sx={{ color: colors.lightGreen }} />
                  </IconButton>
                </Box>
              )}
              {editDataFile && !file && (
                <Box display="flex" gap={1} alignItems="center" mt={1}>
                  {editData?.image > 18
                    ? `${editData?.image.substring(0, 18)}...`
                    : editData?.image}
                  <IconButton
                    onClick={() => setEditDataFile(null)}
                    aria-label="delete"
                    sx={{
                      '&:hover': {
                        background: 'white !important',
                      },
                    }}
                  >
                    <Delete sx={{ color: '#3f96ff' }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <ControlledTextField
            control={control}
            defaultValue={editData?.installationCost ?? ''}
            name="installationCost"
            label="Installation cost, $"
            error={errors}
            width="100%"
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.electricityCost ?? ''}
            name="electricityCost"
            label="Electricity cost, $ (per Watt)"
            error={errors}
            width="100%"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '40px',
          }}
        >
          <Button
            sx={{
              marginRight: '10px',
              background: '#eeeeee',
              color: 'black',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#eeeeee',
              },
            }}
            onClick={() => setShowModal(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            type="submit"
            onClick={(event) => handleClick(event)}
          >
            {editData ? 'Save' : 'Create'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
